import { ReportBenchmark } from '../../../types/ReportBenchmark'
import { BaseStatsResult, StatsResult } from '../../../types/Statistic'
import { ResultSet, SectionAveragesData, SectionResults } from '../../../types/ResultSet'
import sortByDisplayOrder from '../../util/sortByDisplayOrder'

export enum ScoreCollection {
  Average = 'average',
  ComparisonAverage = 'comparisonAverage',
  OrganisationAverage = 'organisationAverage',
  UniverseAverage = 'universeAverage',
  SystemAverage = 'systemAverage'
}

export const ScoreCollectionOrder: ScoreCollection[] = Object.values(ScoreCollection)

export function sortReportBenchmarks (benchmarks: ReportBenchmark[]): ReportBenchmark[] {
  return benchmarks.sort((a: ReportBenchmark, b: ReportBenchmark): number => {
    if (a.collection === b.collection) {
      if (a.version === b.version) {
        return 0
      }
      if (a.version === '1') {
        return -1
      }
      if (b.version === '1') {
        return 1
      }
    }
    return ScoreCollectionOrder.indexOf(a.collection) - ScoreCollectionOrder.indexOf(b.collection)
  })
}

export function normalizeScores (scores: StatsResult[], resultSet: ResultSet): StatsResult[] {
  const groups: SectionAveragesData[] | undefined = resultSet.sections.reduce((subGroups: SectionAveragesData[], section: SectionResults) => {
    if (section.averages?.subSections) {
      section.averages.subSections.forEach((subSection: SectionAveragesData) => {
        if (!subGroups.includes(subSection)) {
          subGroups.push(subSection)
        }
      })
    }

    return subGroups
  }, [])

  const questionnaireSections: SectionResults[] | undefined = resultSet.sections
  if (!questionnaireSections) {
    return scores
  }
  const masterSectionIds: string[] | undefined = questionnaireSections.reduce((masterIds: string[], section: SectionResults): string[] => {
    if (section.masterSectionId) {
      masterIds.push(section.masterSectionId)
    }

    return masterIds
  }, [])

  const normalizedScores: StatsResult[] = scores
    .filter(
      (score: StatsResult): boolean => {
        if (!score.masterSectionId) {
          return false
        }

        return masterSectionIds.includes(score.masterSectionId)
      }
    )
    .map((score: StatsResult): StatsResult => {
      const matchingSection: SectionResults | undefined = questionnaireSections.find(
        (section: SectionResults): boolean => section.masterSectionId === score.masterSectionId
      )
      if (matchingSection) {
        const updatedSubSections: BaseStatsResult[] | undefined = score.subSections?.map((subSection: BaseStatsResult) => {
          const questionnaireGroup: SectionAveragesData | undefined = groups?.find(
            (group: SectionAveragesData): boolean => group.slug === subSection.key
          )
          let index: number | undefined
          if (questionnaireGroup) {
            index = groups?.indexOf(questionnaireGroup)
          }

          return questionnaireGroup
            ? {
              ...subSection,
              name: questionnaireGroup.name,
              displayOrder: index ? index + 1 : undefined
            }
            : subSection
        })

        return {
          ...score,
          name: matchingSection.name,
          subSections: updatedSubSections,
          displayOrder: matchingSection.displayOrder
        }
      }

      return score
    })
    .sort((a: StatsResult, b: StatsResult): number => sortByDisplayOrder(a, b))

  return normalizedScores
}
