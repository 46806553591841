import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection, sortReportBenchmarks } from '@betterboards/shared/helpers/entities/Result/scores'
import { PlatformCompany } from '@betterboards/shared/types/Company'
import buildUniverseBenchmarkOptions from '@/helpers/statistics/buildUniverseBenchmarkOptions'
import buildOrganisationBenchmarkOptions from '@/helpers/statistics/buildOrganisationBenchmarkOptions'
import buildComparisonBenchmarkOptions from '@/helpers/statistics/buildComparisonBenchmarkOptions'
import { getReportBenchmarkDisplayInfo } from '@betterboards/shared/helpers/statistics/getReportBenchmarkDisplayInfo'

export default function buildAvailableReportBenchmarksList (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany,
  companiesList: PlatformCompany[],
  allowMultipleUniverseBenchmarks: boolean
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  availableBenchmarks.push(
    ...buildComparisonBenchmarkOptions(
      existingBenchmarks,
      selectedCompany
    )
  )

  availableBenchmarks.push(
    ...buildOrganisationBenchmarkOptions(
      existingBenchmarks,
      selectedCompany,
      companiesList
    )
  )

  availableBenchmarks.push(
    ...buildUniverseBenchmarkOptions(
      existingBenchmarks,
      allowMultipleUniverseBenchmarks
    )
  )

  const systemBenchmarks: ReportBenchmark[] = existingBenchmarks
    .filter(
      (b: ReportBenchmark): boolean => b.collection === ScoreCollection.SystemAverage // @TODO: Replace with isSystemReportBenchmark helper
    )
    .map((b) => getReportBenchmarkDisplayInfo(b))
    .filter((b: ReportBenchmark | undefined): b is ReportBenchmark => !!b)
  availableBenchmarks.push(
    ...systemBenchmarks
  )

  return sortReportBenchmarks(availableBenchmarks)
}
