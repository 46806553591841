import * as d3 from 'd3'
import { AbstractGraph, GraphConfig, GraphInterface } from './Abstract'
import buildTriangleIconPath from '../helpers/paths/buildTriangleIconPath'

export interface BaseGaugeGraphData {
  title?: string
  subTitle?: string
}

export interface GaugeGraphData extends BaseGaugeGraphData {
  overallScore: number
  quartileBands: QuartileBand[]
  tags?: string[]
  comparisonOverallScore?: number
}

export interface GaugeGraphConfig extends GraphConfig {
  width: number
  height: number
  margin: {
    top: number
    left: number
    right: number
    bottom: number
  }
  scale: number
  legend: boolean
  comparisonOnly: boolean
}

enum Quartile {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

interface QuartileBand {
  lower: number
  upper: number
  quartile: Quartile
}

interface QuartileResult {
  quartile: number
  normalizedValue: number
}

const quartileOrder = {
  [Quartile.Q1]: 1,
  [Quartile.Q2]: 2,
  [Quartile.Q3]: 3,
  [Quartile.Q4]: 4
}

enum TopPercentileBands {
  Top10 = 'Top 10%',
  Top5 = 'Top 5%',
  Top1 = 'Top 1%'
}

const colors = ['green', 'yellow', 'orange', 'red'] // Array of colors for each segment

function percToDeg (percentage: number): number {
  return percentage * 360
}

function degToRad (degree: number): number {
  return (degree * Math.PI) / 180
}

function percToRad (percentage: number): number {
  return degToRad(percToDeg(percentage))
}

export default class GaugeGraph extends AbstractGraph<GaugeGraphConfig> implements GraphInterface<GaugeGraphData> {
  score: number = 0
  comparisonScore: number | undefined = undefined
  quartileBands: QuartileBand[] = []

  thetaRad: number = 0
  comparisonThetaRad: number = 0
  visualPosition: number = 0
  comparisonVisualPosition: number = 0

  private svg!: d3.Selection<SVGElement, any, any, any>
  graphHeight: number = 0
  graphWidth: number = 0
  centerX: number = 0
  centerY: number = 0
  needleLength: number = 0
  needleThickness: number = 0
  innerRadius: number = 0
  outerRadius: number = 0

  public getDefaultConfig (): Partial<GaugeGraphConfig> {
    return {
      width: 700,
      height: 450,
      scale: 1,
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      legend: false,
      comparison: false
    }
  }

  initializeGraph (data: GaugeGraphData): void {
    if (!data.quartileBands) {
      throw new Error('No quartiles defined')
    }

    const config: GaugeGraphConfig = this.config
    this.graphWidth = config.width * 0.8
    this.graphHeight = config.height * 0.8

    const maxRadius: number = Math.min(this.graphWidth, this.graphHeight) / 2
    this.outerRadius = maxRadius * 0.9 // 90% of the max radius
    this.innerRadius = maxRadius * 0.6 // 60% of the max radius

    this.centerX = (this.graphWidth / 2)
    this.centerY = (this.graphHeight / 2)

    this.needleLength = this.outerRadius
    this.needleThickness = this.outerRadius * 0.02

    this.quartileBands = data.quartileBands.sort(
      (a: QuartileBand, b: QuartileBand) => quartileOrder[a.quartile] - quartileOrder[b.quartile]
    )

    this.score = data.overallScore // 4.01 3.4
    this.quartileBands = adjustQuartileBands(this.score, this.quartileBands)

    this.comparisonScore = normalizeComparisonScore(
      data.comparisonOverallScore,
      this.quartileBands[this.quartileBands.length - 1].upper,
      this.quartileBands[0].lower
    )

    const result: QuartileResult = getQuartileResult(this.score, this.quartileBands)
    this.visualPosition = calculateVisualPosition(result.quartile, result.normalizedValue)
    this.thetaRad = percToRad(this.visualPosition) / 2

    if (this.comparisonScore) {
      const comparisonResult: QuartileResult = getQuartileResult(this.comparisonScore, this.quartileBands)
      this.comparisonVisualPosition = calculateVisualPosition(comparisonResult.quartile, comparisonResult.normalizedValue)
      this.comparisonThetaRad = percToRad(this.comparisonVisualPosition) / 2
    }
  }

  render (data: GaugeGraphData): void {
    this.initializeGraph(data)
    super.render(data)

    this.svg = d3
      .select(this.selector)

    // Clear current contents (in case this is a re-render)
    this.svg
      .selectAll('*')
      .remove()

    const centerGraphTranslate: string = `translate(${this.centerX}, ${this.centerY})`

    const g = this.svg.append('g')
      .attr('transform', centerGraphTranslate)

    const angles = [
      { startAngle: Math.PI / 2, endAngle: Math.PI / 4 }, // First quarter
      { startAngle: Math.PI / 4, endAngle: 0 }, // Second quarter
      { startAngle: 0, endAngle: -Math.PI / 4 }, // Third quarter
      { startAngle: -Math.PI / 4, endAngle: -Math.PI / 2 } // Fourth quarter
    ]

    angles.forEach((angle, i: number): void => {
      const arc = d3.arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.outerRadius)
        .startAngle(angle.startAngle)
        .endAngle(angle.endAngle)

      g.append('path')
        .attr('d', arc)
        .attr('fill', colors[i])
        .attr('stroke-width', 1)
        .attr('stroke', colors[i])
    })

    this.createNeedle()

    if (this.comparisonScore) {
      this.createNeedle(true)
    }
  }

  createNeedle (comparison?: boolean): void {
    if (!comparison) {
      const radius: number = this.needleThickness * 1.5
      this.svg
        .append('circle')
        .attr('cx', this.centerX)
        .attr('cy', this.centerY)
        .attr('r', radius)
        .attr('fill', 'black')
    }

    // Handle comparison score
    if (comparison) {
      this.createNeedleLine(
        this.comparisonThetaRad,
        '12,5',
        true
      )

      this.createNeedleTip(
        this.comparisonThetaRad,
        this.config.colors.secondary,
        undefined,
        true,
        inCloseProximity(this.visualPosition, this.comparisonVisualPosition)
      )

      return
    }

    this.createNeedleLine(
      this.thetaRad,
      this.config.comparisonOnly ? '6,5' : undefined,
      this.config.comparisonOnly
    )
    const topPercentile: TopPercentileBands | undefined = checkTopPercentile(
      this.score,
      this.quartileBands[0].lower,
      this.quartileBands[this.quartileBands.length - 1].upper
    )

    this.createNeedleTip(
      this.thetaRad,
      this.config.comparisonOnly ? this.config.colors.secondary : this.config.colors.primary,
      topPercentile,
      this.config.comparisonOnly,
      inCloseProximity(this.visualPosition, this.comparisonVisualPosition)
    )
  }

  // Function to create a needle
  createNeedleLine (
    thetaRad: number,
    dashArray?: string,
    isTriangle: boolean = false // TODO: remove?
  ): {
    endX: number
    endY: number
  } {
    const needleOffset: number = isTriangle ? this.needleLength : (this.needleLength + this.needleThickness)
    const endX: number = this.centerX - needleOffset * Math.cos(thetaRad)
    const endY: number = this.centerY - needleOffset * Math.sin(thetaRad)

    const line = this.svg
      .append('line')
      .attr('x1', this.centerX)
      .attr('y1', this.centerY)
      .attr('x2', endX)
      .attr('y2', endY)
      .attr('stroke', 'black')
      .attr('stroke-width', this.needleThickness)
    if (dashArray) {
      line.attr('stroke-dasharray', dashArray)
    }

    return { endX, endY }
  }

  createNeedleTip (
    thetaRad: number,
    color: string,
    topPercentile?: TopPercentileBands | undefined,
    isTriangle: boolean = false,
    overlap: boolean = false
  ): void {
    const circleXPosition: number = this.centerX - (this.needleLength + (this.needleThickness * 2.5)) * Math.cos(thetaRad)
    const circleYPosition: number = this.centerY - (this.needleLength + (this.needleThickness * 2.5)) * Math.sin(thetaRad)

    const adjustedThetaRad: number = thetaRad + (Math.PI / 2)
    const thetaDegree: number = (adjustedThetaRad * 180) / Math.PI

    const horizontalSideLength: number = Math.round(6 * this.config.scale)
    const verticalSideLength: number = Math.round(10 * this.config.scale)
    const triangleOffset: number = calculateTriangleHeight(horizontalSideLength, verticalSideLength)

    const radius: number = this.needleThickness * 2

    if (isTriangle) {
      const triangleXPosition: number = this.centerX - (this.needleLength + this.needleThickness) * Math.cos(thetaRad)
      const triangleYPosition: number = this.centerY - (this.needleLength + this.needleThickness) * Math.sin(thetaRad)

      const triangleX: number = triangleXPosition
      const triangleY: number = overlap
        ? triangleYPosition + (triangleOffset * 1.2)
        : triangleYPosition

      this.svg
        .append('path')
        .attr('d', buildTriangleIconPath(triangleX, triangleY, this.config.scale))
        .attr('fill', color)
        .attr('stroke', color)
        .attr('stroke-width', this.config.scale)
        .attr('transform', `rotate(${thetaDegree}, ${triangleXPosition}, ${triangleYPosition})`)
    } else {
      this.svg
        .append('circle')
        .attr('cx', circleXPosition)
        .attr('cy', circleYPosition)
        .attr('r', radius)
        .attr('fill', color)
    }

    if (topPercentile && !this.config.comparisonOnly) {
      const offsetTop: boolean = !!(this.comparisonScore && this.score < this.comparisonScore)

      const textSize: number = 6 * this.config.scale
      const xOffset: number = overlap ? radius : radius + triangleOffset
      const yOffset: number = textSize + radius

      const textXPosition: number = circleXPosition + xOffset

      const offsetYPosition: number = offsetTop
        ? circleYPosition - yOffset
        : circleYPosition + yOffset
      const textYPosition: number = overlap
        ? offsetYPosition
        : circleYPosition

      const topPercentileText: string = topPercentile

      this.svg
        .append('text')
        .attr('x', textXPosition) // X position of the text
        .attr('y', textYPosition) // Y position of the text
        .attr('text-anchor', 'middle') // Centers the text horizontally
        .attr('font-family', 'sans-serif') // Font family
        .attr('font-size', `${textSize}`) // Font size
        .attr('fill', color) // Text color
        .text(topPercentileText) // Text content
    }
  }
}

/**
 * Calculate which quartile a score falls into and its normalized value.
 * @param score The score to evaluate.
 * @param quartiles The quartile bands.
 * @returns The quartile index and the normalized value within that quartile.
 */
function getQuartileResult (score: number, quartiles: QuartileBand[]): QuartileResult {
  for (let i: number = 0; i < quartiles.length; i++) {
    const { lower, upper } = quartiles[i]

    const withinLowerBand: boolean = score >= lower
    const withinUpperBand: boolean = i === quartiles.length - 1
      ? score <= upper
      : score < upper

    if (withinLowerBand && withinUpperBand) {
      return {
        quartile: i,
        normalizedValue: Math.min(
          (score - lower) / (upper - lower),
          1
        ) ?? 0
      }
    }
  }

  console.debug('Unexpected Quartile error', { quartiles, score })
  throw new Error(`Unexpected Quartile error. ${score}`)
}

/**
 * Calculate the visual position on the graph based on the fixed visual bands.
 * @param quartile The number quartile the score belongs too.
 * @param normalizedValue The normalized value for the given band.
 * @returns The visual position required to pass to createNeedle
 */
function calculateVisualPosition (quartile: number, normalizedValue: number): number {
  return (quartile) * 0.25 + (normalizedValue * 0.25)
}

function inCloseProximity (num1: number, num2: number, tolerance: number = 0.02): boolean {
  return Math.abs(num1 - num2) <= tolerance
}

function checkTopPercentile (score: number, min: number, max: number): TopPercentileBands | undefined {
  const rangeLength: number = max - min

  const top10PercentCutoff: number = max - 0.1 * rangeLength
  const top5PercentCutoff: number = max - 0.05 * rangeLength
  const top1PercentCutoff: number = max - 0.01 * rangeLength

  if (score >= top1PercentCutoff) {
    return TopPercentileBands.Top1
  }
  if (score >= top5PercentCutoff) {
    return TopPercentileBands.Top5
  }
  if (score >= top10PercentCutoff) {
    return TopPercentileBands.Top10
  }

  return undefined
}

function adjustQuartileBands (score: number, quartileBands: QuartileBand[]): QuartileBand[] {
  if (score < quartileBands[0].lower) {
    quartileBands[0].lower = score
  }
  if (score > quartileBands[quartileBands.length - 1].upper) {
    quartileBands[quartileBands.length - 1].upper = score
  }

  return quartileBands
}

function normalizeComparisonScore (comparisonScore: number | undefined, upperLimit: number, lowerLimit: number): number | undefined {
  if (!comparisonScore) {
    return undefined
  }
  if (comparisonScore > upperLimit) {
    return upperLimit
  }
  if (comparisonScore < lowerLimit) {
    return lowerLimit
  }

  return comparisonScore
}

function calculateTriangleHeight (base: number, side: number): number {
  if (base / 2 >= side) {
    throw new Error('Invalid dimensions: side must be longer than half the base.')
  }
  return Math.sqrt(Math.pow(side, 2) - Math.pow(base / 2, 2))
}
